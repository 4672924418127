import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppOpinion from "../../Shared/AppOpinion";
import AppComment from "../../Shared/AppComment";
import {Link} from "react-router-dom";


const OpinionDetails = ({history, location, match, ...props}) => {

    const [opinion, setOpinion] = useState([]);
    const [fetching, setFetching] = useState(true);

    const fetchOpinion = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/opinions/${match.params.id}`);
        setOpinion(response.data);
        cb();
    };

    useEffect(() => {
        try {
            fetchOpinion({}, () => setFetching(false));
        } catch (e) {
            throw e;
        }
    }, []);

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Details opinion">
                <div className="col-md-6 col-sm-12 text-right hidden-xs">
                    <Link to={'/opinions'}>
                        <button className="btn btn-sm btn-default">
                            <i className="fa fa-arrow-left"></i>{' '}
                            <span>Retour</span>
                        </button>
                    </Link>
                </div>
            </AppBreadcrumb>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="testimonials">
                                <AppOpinion
                                    user={opinion.user}
                                    opinion={opinion}
                                    shouldTruncate={false}
                                />
                            </div>
                            <div className="ml-5">
                                {opinion.comments.map(comment => (
                                    <>
                                        <AppComment
                                            user={comment.user}
                                            comment={comment}
                                            key={comment.com_id}
                                        />
                                        <div className="ml-5">
                                            {comment.comments.map(subcomment => (
                                                <AppComment
                                                    user={subcomment.user}
                                                    comment={subcomment}
                                                    key={subcomment.com_id}
                                                />
                                            ))}
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OpinionDetails;