import React, {useEffect, useState} from 'react';
import queryString from "query-string";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import AppComment from "../../Shared/AppComment";
import AppOpinion from "../../Shared/AppOpinion";

const ReportList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const initialValues = {
        keyword: query.keyword
    }

    const [fetching, setFetching] = useState(true);
    const [reports, setReports] = useState([]);

    const fetchReports = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/reports`, {
            params
        });
        setReports(response.data);
        cb();
    }

    useEffect(() => {

        try {

            fetchReports(initialValues, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, []);

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Historique des opinions et commentaires signalees"/>
            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        {reports.map(report => {

                            const ReportDetails = () => (
                                <div className="card border-danger">
                                    <div className="body text-danger p-3">
                                        <small className="text-muted">Utilisateur:</small>
                                        <p className="mb-0">{report.reports[0].user.user_surname} {report.reports[0].user.user_name} (@{report.reports[0].user.user_username})</p>
                                        <small className="text-muted">Signale le:</small>
                                        <p className="mb-0">{report.reports[0].report_date}</p>
                                        <small className="text-muted">Raisons:</small>
                                        <p className="mb-0">{report.reports[0].reasons.join(', ')}</p>
                                        <small className="text-muted">Description:</small>
                                        <p className="mb-0">{report.reports[0].descr || 'N/A'}</p>
                                    </div>
                                </div>
                            )

                            if(report.com_id)
                            {
                                return (
                                    <div className="row">
                                        <div className="col-md-8">
                                            <AppComment
                                                user={report.user}
                                                comment={report}
                                                key={report.com_id}
                                                truncateLength={300}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <ReportDetails />
                                        </div>
                                    </div>

                                );
                            }
                            else if(report.sond_id)
                            {
                                return (
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="card mb-0">
                                                <div className="testimonials">
                                                    <AppOpinion
                                                        key={report.sond_id}
                                                        user={report?.user}
                                                        opinion={report}
                                                        truncateLength={350}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <ReportDetails />
                                        </div>
                                    </div>
                                );
                            }
                            else
                            {
                                return null;
                            }

                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReportList;