const metisMenu = [
	{
		"id": 1,
		"icon": "icon-home",
		"label": "Tableau de bord",
		"to": "/",
	},
	{
		"id": 2,
		"icon": "icon-pie-chart",
		"label": "Statistiques",
		"content": [
			{
				"id": 2.1,
				"label": "Badges utilisateurs",
				"to": "/stats/users_badges"
			}
		]
	},
	{
		"id": 3,
		"icon": "icon-paper-clip",
		"label": "MyOpinion",
		"content": [
			{
				"id": 3.1,
				"label": "Utilisateurs",
				"to": "/users",
			},
			{
				"id": 3.2,
				"label": "Opinions",
				"to": "/opinions",
			},
			{
				"id": 3.3,
				"label": "Commentaires",
				"to": "/comments",
			},
			{
				"id": 3.4,
				"label": "Agoras",
				"to": "/agoras",
			},
			{
				"id": 3.5,
				"label": "Reports",
				"to": "/reports",
			},
			{
				"id": 3.6,
				"label": "Awards",
				"to": "/awards",
			},
			{
				"id": 3.7,
				"label": "Verifications",
				"to": "/verifications",
			},
			{
				"id": 3.8,
				"label": "Abonnements",
				"to": "/subscriptions",
			},
		]
	},
	{
		"id": 4,
		"icon": "icon-users",
		"label": "Ressources humaines",
		"content": [
			{
				"id": 4.1,
				"label": "Soldes de conges",
				"to": "/hr/holidays_balance"
			},
		]
	},
	{
		"id": 5,
		"icon": "icon-lock",
		"label": "Securite",
		"content": [
			{
				"id": 5.1,
				"label": "Hubs",
				"to": "/hubs"
			},
			{
				"id": 5.2,
				"label": "Autorisations",
				"to": "/authorizations",
			},
			{
				"id": 5.3,
				"label": "Moderateurs",
				"to": "/moderateurs",
			},
		]
	},
];

export default metisMenu	