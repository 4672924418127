import React, {useEffect, useState} from 'react';
import httpService from "../../../services/http.service";
import {Link} from "react-router-dom";
import MyOpinionLogo from '../../../assets/images/MyOpinion_Logo_Head.png';
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppInput from "../../Shared/AppInput";
import {useFormik} from "formik";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import AppUser from "../../Shared/AppUser";
import UserCard from "../../Shared/User/Card";
const classnames = require('classnames');

const UserList = ({...props}) => {

    const initialValues = {
        user_id: '',
        user_username: '',
        user_name: '',
        user_surname: '',
        user_email: '',
    };

    const [users, setUsers] = useState([]);
    const [fetching, setFetching] = useState(true);

    const fetchUser = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/users`, {
            params
        });
        setUsers(response.data);
        cb();
    }

    const onReset = () => {
        formik.resetForm(initialValues);
        onSubmit(initialValues);
    }

    const onSubmit = (values) => {

        setFetching(true);
        try {
            fetchUser(values, () => {
                setFetching(false);
            })
        } catch (e) {
            throw e;
        }

    };

    useEffect(() => {
        try {
            fetchUser({}, () => setFetching(false));
        } catch (e) {
            throw e;
        }
    }, []);

    const formik = useFormik({
        initialValues,
        onSubmit
    })

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Utilisateurs" />

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card border shadow-sm">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-header bg-info text-white font-weight-bold">
                                <i className="fa fa-search"></i>{' '}
                                <span>Filtre de recherche</span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-7">
                                        <AppInput
                                            name="user_id"
                                            placeholder="Identifiant"
                                            value={formik.values.user_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <AppInput
                                            name="user_username"
                                            placeholder="Nom d'utilisateur"
                                            value={formik.values.user_username}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <AppInput
                                            placeholder="Nom"
                                            name="user_surname"
                                            value={formik.values.user_surname}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <AppInput
                                            placeholder="Prenom"
                                            name="user_name"
                                            value={formik.values.user_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                <AppInput
                                    placeholder="Adresse email"
                                    name="user_email"
                                    value={formik.values.user_email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="card-footer bg-white text-right">
                                <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                    <i className="fa fa-times"></i>{' '}
                                    <span className="font-weight-bold">Annuler</span>
                                </button>
                                <button type="submit" className="btn btn-success">
                                    <i className="fa fa-check"></i>{' '}
                                    <span className="font-weight-bold">Rechercher</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    {users.map(user => (
                        <div className="col-md-6" key={user?.user_id}>
                            <UserCard user={user}/>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default UserList