export const extractResponseValidationErrors = ({ errors }) => {

    const responseErrors = [];

    for(let error in errors) {
        responseErrors.push(errors[error][0]);
    }

    return responseErrors;

}

export const generateColor = () => {

    const color = '#' + Math.floor(Math.random()*16777215).toString(16);

    if(color === '#000000' || color === '#ffffff') {
        return generateColor();
    }

    return color;
}

export const getRandomColor = () => {
    const trans = '1'; // transparency
    let color = 'rgba(';
    for (let i = 0; i < 3; i++) {
        color += Math.floor(Math.random() * 255) + ',';
    }
    color += trans + ')'; // add the transparency
    return color;
}

export const VERIFIED = 'Verified';
export const GOLD = 'Gold';

export const MONTH = "Month";
export const YEAR = "Year";


export const numberWithSeparator = (x, separator = ' ') => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, separator);
}

export function formatCash(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const PERIODICITY_DAILY = 'DAILY';
export const PERIODICITY_WEEKLY = 'WEEKLY';
export const PERIODICITY_MONTHLY = 'MONTHLY';
export const PERIODICITY_YEARLY = 'YEARLY';