import axios from "axios";
import * as jwtService from './jwt.service';

//http://MyOpinionDashboard.test/api
//https://api.admin.myopinion.jdavprojects.com/api
//https://api.customercare.myopinion.ci/api
//https://api.customercare.socialhub.nsiagroup.myopinion.ci/api

const baseURL = 'https://api.admin.myopinion.jdavprojects.com/api';

const jwt = jwtService.get();

const config = {
    baseURL,
}

if(jwt) {
    config['headers'] = {
        Authorization: `Bearer ${jwt?.access_token}`
    }
}

const instance = axios.create(config);

instance.interceptors.response.use(
    response => response,
    error => {
        if(error.response.status == 401 && window.location.pathname !== '/login') {
            return jwtService.remove(() => {
                window.location.replace('/login');
            });
        }

        return Promise.reject(error);
    });

export default instance;