import React, {useEffect, useState} from 'react';
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {Link} from "react-router-dom";

const UserFollowers = ({user, ...props}) => {

    const [fectching, setFectching] = useState(true);
    const [followers, setFollowers] = useState([]);

    const fetchFollowers = async (username, cb = () => null) => {
        const { data: response } = await httpService.get(`/users/${username}/followers`);
        setFollowers(response.data);
        cb();
    }

    useEffect(() => {

        try {
            fetchFollowers(user.user_username, () => {
                setFectching(false);
            });
        } catch (e) {
            throw e;
        }

    }, []);

    if(fectching) {
        return <FecthingSpinner />
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="table-responsive">
                        <table className="table table-sm table-hover table-custom spacing5">
                            <tbody>
                            {followers.map(follower => (
                                <tr key={follower.user_id}>
                                    <td className="w60">
                                        <img src={follower?.profile?.prof_picture || MyOpinionLogo}
                                             data-toggle="tooltip"
                                             data-placement="top"
                                             title=""
                                             alt="Avatar"
                                             className="w35 rounded"
                                             data-original-title="Avatar Name" />
                                    </td>
                                    <td>
                                        <Link to={`/users/${follower.user_username}`}>
                                            {follower.user_surname} {follower.user_name}{' '}
                                            {follower?.user_verified && (<span className="badge badge-primary" title="Compte certifie">
                                            <i className="fa fa-check"></i>
                                        </span>)}
                                        </Link>
                                        <p className="mb-0">
                                            @{follower.user_username}{' '}{follower?.user_muted ? (
                                            <span className="badge badge-danger" title="Mute">
                                            <i className="fa fa-volume-off"></i>
                                        </span>
                                        ) : null}
                                        </p>
                                    </td>
                                    <td>Fan depuis le {follower.fan_created}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )

}

export default UserFollowers;