import React from 'react';

const UserProfile = ({user, ...props}) => {
    return (
        <>
            <div className="row">
                <div className="card">
                    <div className="body">
                        {user.user_verified && (<p>
                            <span className="badge badge-success" title="Compte certifie">Compte certifie</span>
                        </p>)}
                        {user.user_gold && (<p>
                            <span className="badge badge-warning" title="Compte premium">Compte premium</span>
                        </p>)}
                        <small className="text-muted">Identifiant</small>
                        <p>#{user.user_id}</p>
                        <small className="text-muted">Nom d'utilisateur</small>
                        <p>@{user.user_username}</p>
                        <small className="text-muted">Nom complet</small>
                        <p>{user.user_surname} {user.user_name}</p>
                        <small className="text-muted">Titre</small>
                        <p>{user.profile.prof_title || '-'}</p>
                        <small className="text-muted">Bio</small>
                        <p>{user.profile.prof_description || '-'}</p>
                        <small className="text-muted">Email</small>
                        <p>{user.user_email}</p>
                        <small className="text-muted">Statut du profil</small>
                        <p>
                            {user.user_active ? (
                                <span className="badge badge-success" title="Actif">Actif</span>
                            ) : (
                                <span className="badge badge-danger" title="Inactif">Inactif</span>
                            )}
                        </p>
                        <small className="text-muted">Ville</small>
                        <p>{user.user_ville || '-'}</p>
                        <small className="text-muted">Membre depuis le</small>
                        <p>{user.user_created_at}</p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfile;