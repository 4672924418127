import classnames from "classnames";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {Link} from "react-router-dom";
import React from "react";

const UserCardBody = ({user, className = ''}) => {
    return (
        <div className={className}>
            <div>
                <img
                    loading="lazy"
                    className={classnames({'rounded-circle' : !!user?.profile?.prof_picture})}
                    height="128px"
                    width="128px"
                    src={user?.profile?.prof_picture || MyOpinionLogo} alt="" />
            </div>
            <h6 className="mt-3 mb-0">
                {`${user?.user_surname} ${user?.user_name}`}{' '}
                {user.user_verified && (
                    <i style={{cursor: 'pointer'}} title="Verified" className="fa fa-check-circle-o text-primary mr-1"/>
                )}
                {user.user_gold && (
                    <i style={{cursor: 'pointer'}} title="Premium / Gold" className="fa fa-star text-warning mr-1"/>
                )}
            </h6>
            <span>{user?.user_email}</span>
            <br/>
            <span className="font-italic font-weight-bold font-12">
                                        @{user?.user_username}{' '}{user?.user_muted ? (
                <span className="badge badge-danger" title="Mute">
                                            <i className="fa fa-volume-off"></i>
                                        </span>
            ) : null}
                                    </span>
            <div className="mt-2">
                <Link to={`/users/${user?.user_username}`}>
                    <button className="btn btn-primary btn-sm">
                        <i className="fa fa-eye"></i>{' '}
                        <span className="font-weight-bold">Voir le profil</span>
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default UserCardBody;