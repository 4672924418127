import React, {useEffect, useState} from 'react';
import queryString from "query-string";
import httpService from "../../services/http.service";
import AppBreadcrumb from "../Shared/AppBreadcrumb";
import FecthingSpinner from "../Shared/FecthingSpinner";
import axios from "axios";
import {useFormik} from "formik";
import * as Yup from "yup";
import useAlert from "../../hooks/useAlert";
import {Form, FormGroup} from "reactstrap";
import AppAlert from "../Shared/AppAlert";
import AppInput from "../Shared/AppInput";
import {extractResponseValidationErrors} from "../../utils/index";

const Hub = ({history, location, match, ...props}) => {

    const initialValues = {
        hub_name: '',
        hub_api_endpoint: '',
    };

    const query = queryString.parse(location.search);

    const [alert, setAlert, onClose] = useAlert();
    const [submitting, setSubmitting] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [hubs, setHubs] = useState([]);
    const [modal, setModal] = useState({hubEditForm: false});
    const [selectedHub, setSelectedHub] = useState(null);

    const onSubmit = async (values) => {

        setSubmitting(true);
        setAlert(null);

        try {

            await validateHubEndpoint(values?.hub_api_endpoint);

            try {

                const editMode = !!selectedHub;

                if(editMode) {
                    const {data: response} = await httpService.put(`hub/${selectedHub?.hub_id}`, values);

                    const newArr = hubs.map(obj => {
                        if(obj?.hub_id === selectedHub?.hub_id) {
                            return {...response?.data};
                        }

                        return obj;
                    });

                    setHubs(newArr);
                }
                else {
                    const {data: response} = await httpService.post(`hub`, values);
                    setHubs(state => [...state, response?.data]);
                }

                onCancelHubEdit();

            }
            catch ({response}) {
                const {data, status} = response;

                if(response && status === 422) {
                    setAlert({
                        type: 'danger',
                        message: extractResponseValidationErrors(data)[0]
                    })
                }
                else if(response && status === 500) {
                    setAlert({
                        type: 'danger',
                        message: 'Erreur de traitement, veuillez contacter les administrateurs'
                    })
                }
            }

        } catch (e) {
            formik.setFieldError('hub_api_endpoint', 'URL Invalide');
        }
        finally {
            setSubmitting(false);
        }

    };

    const fetchHubs = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/hub`, {
            params
        });
        setHubs(response.data);
        cb();
    };

    const toggleHubLock = async (id, callback = () => null) => {
        const {data: response} = await httpService.post(`/hub/${id}/toggleLock`);
        callback(response);
        return response;
    };

    const lockHub = async (apiEndpoint, callback = () => null) => {
        const {data: response} = await axios.post(`${apiEndpoint}/hub/lock`);
        callback(response);
        return response;
    }

    const unlockHub = async (apiEndpoint, callback = () => null) => {
        const {data: response} = await axios.post(`${apiEndpoint}/hub/unlock`);
        callback(response);
        return response;
    }

    const validateHubEndpoint = async (apiEndpoint, callback = () => null) => {
        const {data: response} = await axios.get(`${apiEndpoint}/hub/status`);
        callback(response);
        return response;
    };

    const onLockHub = async (hub) => {
        if(!window.confirm('Confirmer ?')) return;

        try {

            const [] = await Promise.all([
                lockHub(hub?.hub_api_endpoint),
                toggleHubLock(hub?.hub_id),
            ]);

            const newArr = hubs.map(obj => {
                if(obj?.hub_id === hub?.hub_id) return {
                    ...hub,
                    hub_locked: !hub?.hub_locked
                };

                return obj;
            });

            setHubs(newArr);
        } catch (e) {
            throw e;
        }
    };

    const onUnlockHub = async (hub) => {
        if(!window.confirm('Confirmer ?')) return;

        try {

            const [] = await Promise.all([
                unlockHub(hub?.hub_api_endpoint),
                toggleHubLock(hub?.hub_id),
            ]);

            const newArr = hubs.map(obj => {
                if(obj?.hub_id === hub?.hub_id) return {
                    ...hub,
                    hub_locked: !hub?.hub_locked
                };

                return obj;
            });

            setHubs(newArr);
        } catch (e) {
            throw e;
        }
    };

    const onUpdateHub = (hub) => {
        setSelectedHub(hub);
        formik.setValues({
            ...initialValues,
            hub_name: hub?.hub_name,
            hub_api_endpoint: hub?.hub_api_endpoint,
        });
        setModal(state => ({...state, hubEditForm: true}));
    };

    const onDeleteHub = async (hub) => {
      if(!window.confirm('Confirmer ?')) return;

      await httpService.delete(`hub/${hub?.hub_id}`);

      const newArr = hubs.filter(obj => obj?.hub_id !== hub?.hub_id);

      setHubs(newArr);

    };

    const onCancelHubEdit = () => {
        formik.resetForm({...initialValues});
        setAlert(null);
        setSelectedHub(null);
        setModal(state => ({...state, hubEditForm: false}));
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            hub_name: Yup.string().required('Champ obligatoire'),
            hub_api_endpoint: Yup.string().required('Champ obligatoire'),
        }),
        onSubmit
    });

    useEffect(() => {

        try {

            fetchHubs({}, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, []);

    return (
        <>
            <div className="container-fluid">
                <AppBreadcrumb title="Hubs"/>
                {fetching ? <FecthingSpinner /> : (
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <button className="btn btn-success btn-sm mr-1 font-weight-bold" onClick={() => setModal(state => ({...state, hubEditForm: true}))}>
                                <i className="fa fa-plus"></i>{' '}
                                <span>Nouveau hub distant</span>
                            </button>
                        </div>
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-hover table-custom spacing8">
                                    <thead>
                                    <tr>
                                        <th>Hub</th>
                                        <th>Base API</th>
                                        <th>Statut</th>
                                        <th>-</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {hubs.map(obj => (
                                        <tr key={obj?.hub_id}>
                                            <td>{obj?.hub_name}</td>
                                            <td>{obj?.hub_api_endpoint}</td>
                                            <td>
                                                {obj?.hub_locked ? (
                                                    <span className="badge badge-danger">Verrouillé</span>
                                                ) : (
                                                    <span className="badge badge-success">Deverouillé</span>
                                                )}
                                            </td>
                                            <td>
                                                {obj?.hub_locked && (
                                                    <button className="btn btn-outline-success btn-sm font-weight-bold btn-sm" onClick={() => onUnlockHub(obj)}>
                                                        <i className="fa fa-unlock"></i>{' '}
                                                        Deverouiller
                                                    </button>
                                                )}
                                                {!obj?.hub_locked && (
                                                    <>
                                                        <button className="btn btn-outline-warning btn-sm font-weight-bold btn-sm mr-1" onClick={() => onLockHub(obj)}>
                                                            <i className="fa fa-lock"></i>{' '}
                                                            Verouiller
                                                        </button>
                                                        <button className="btn btn-outline-info btn-sm font-weight-bold mr-1" onClick={() => onUpdateHub(obj)}>
                                                            <i className="fa fa-edit"></i>{' '}
                                                            Editer
                                                        </button>
                                                        <button className="btn btn-outline-danger btn-sm font-weight-bold" onClick={() => onDeleteHub(obj)}>
                                                            <i className="fa fa-trash"></i>{' '}
                                                            Supprimer
                                                        </button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className={`modal fade ${modal.hubEditForm ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Mise a jour du Hub distant</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancelHubEdit}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <FormGroup>
                                    <AppInput
                                        label="Hub"
                                        name="hub_name"
                                        error={formik.errors.hub_name}
                                        touched={formik.touched.hub_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.hub_name}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <AppInput
                                        label="Base API"
                                        name="hub_api_endpoint"
                                        type="url"
                                        error={formik.errors.hub_api_endpoint}
                                        touched={formik.touched.hub_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.hub_api_endpoint}
                                        placeholder="https://domain.com/api"
                                        required
                                    />
                                </FormGroup>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancelHubEdit}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Enregistrement...' : 'Enregistrer'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );

};

export default Hub;