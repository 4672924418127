import React, {useEffect, useState} from 'react';
import queryString from "query-string";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";

const ReportDetails = () => {

    return (
        <>
        </>
    );

}

export default ReportDetails;