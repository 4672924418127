import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppOpinion from "../../Shared/AppOpinion";
import {useFormik} from "formik";
import AppInput from "../../Shared/AppInput";
const queryString = require('query-string');

const OpinionList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const initialValues = {
        sond_id: query.sond_id,
        keyword: query.keyword
    };

    const [opinions, setOpinions] = useState([]);
    const [fetching, setFetching] = useState(true);

    const fetchOpinions = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/opinions`, {
            params
        });
        setOpinions(response.data);
        cb();
    };

    const onReset = () => {
        formik.resetForm(initialValues);
        onSubmit(initialValues);
    }

    const onSubmit = async (values) => {

        setFetching(true);
        try {
            fetchOpinions(values, () => {
                setFetching(false);
            })
        } catch (e) {
            throw e;
        }

        history.push(`/opinions?${queryString.stringify(values)}`);


    }

    useEffect(() => {
        try {
            fetchOpinions(initialValues, () => setFetching(false));
        } catch (e) {
            throw e;
        }
    }, []);

    const formik = useFormik({
        initialValues,
        onSubmit
    });

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Historiques de opinions publiees" />

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card border shadow-sm">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-header bg-info text-white font-weight-bold">
                                <i className="fa fa-search"></i>{' '}
                                <span>Filtre de recherche</span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <AppInput
                                            name="sond_id"
                                            placeholder="Identifiant"
                                            value={formik.values.sond_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <AppInput
                                            name="keyword"
                                            placeholder="Mot cle"
                                            value={formik.values.keyword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white text-right">
                                <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                    <i className="fa fa-times"></i>{' '}
                                    <span className="font-weight-bold">Annuler</span>
                                </button>
                                <button type="submit" className="btn btn-success">
                                    <i className="fa fa-check"></i>{' '}
                                    <span className="font-weight-bold">Rechercher</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="testimonials">
                                {opinions.map(opinion => (
                                    <AppOpinion
                                        key={opinion.sond_id}
                                        user={opinion?.user}
                                        opinion={opinion}
                                        truncateLength={350}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default OpinionList;