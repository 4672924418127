import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppComment from "../../Shared/AppComment";
import {useFormik} from "formik";
import AppInput from "../../Shared/AppInput";
const queryString = require('query-string');

const CommentList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const initialValues = {
        com_id: query.com_id,
        keyword: query.keyword
    };

    const [fetching, setFetching] = useState(true);
    const [comments, setComments] = useState([]);

    const fetchComments = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/comments`, {
            params
        });
        setComments(response.data);
        cb();
    };

    const onSubmit = async (values) => {
        setFetching(true);
        try {
            fetchComments(values, () => {
                setFetching(false);
            });
        } catch (e) {
            throw e;
        }

        history.push(`/comments?${queryString.stringify(values)}`);
    }

    const onReset = () => {
        formik.resetForm(initialValues);
        onSubmit(initialValues);
    }

    useEffect(() => {
        try {

            fetchComments(initialValues, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }
    }, []);

    const formik = useFormik({
        initialValues,
        onSubmit
    });

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Historiques de commentaires" />

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card border shadow-sm">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-header bg-info text-white font-weight-bold">
                                <i className="fa fa-search"></i>{' '}
                                <span>Filtre de recherche</span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <AppInput
                                            name="com_id"
                                            placeholder="Identifiant"
                                            value={formik.values.com_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <AppInput
                                            name="keyword"
                                            placeholder="Mot cle"
                                            value={formik.values.keyword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white text-right">
                                <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                    <i className="fa fa-times"></i>{' '}
                                    <span className="font-weight-bold">Annuler</span>
                                </button>
                                <button type="submit" className="btn btn-success">
                                    <i className="fa fa-check"></i>{' '}
                                    <span className="font-weight-bold">Rechercher</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        {comments.map(comment => (
                            <AppComment
                                user={comment.user}
                                comment={comment}
                                key={comment.com_id}
                                truncateLength={300}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default CommentList;