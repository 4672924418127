import React, {useEffect, useState} from 'react';
import httpService from "../../../services/http.service";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import {Link, Route, Switch} from "react-router-dom";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import UserOpinions from "./opinions";
import UserProfile from "./profile";
import UserComments from "./comments";
import UserAgoras from "./agoras";
import UserFriends from "./friends";
import UserFollowers from "./followers";
import {Form, FormGroup} from "reactstrap";
import AppAlert from "../../Shared/AppAlert";
import AppInput from "../../Shared/AppInput";
import AppCheckbox from "../../Shared/AppCheckbox";
import useAlert from "../../../hooks/useAlert";
import {useFormik} from "formik";
import AppDatePicker from "../../Shared/AppDatePicker";
import * as Yup from 'yup';
import {extractResponseValidationErrors, VERIFIED, GOLD} from "../../../utils/index";
import UserSubscriptions from "./subscriptions";
import AppSelect from "../../Shared/AppSelect";

const classnames = require('classnames');

const UserDetails = ({history, location, match, ...props}) => {

    const initialValues = {
        user_unmute_at: ''
    }

    const subscriptionInitialValues = {
        subscr_type: '',
        subscr_period: '',
        subscr_user_id: '',
    };

    const [user, setuser] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [modal, setModal] = useState({
        mute: false,
        subscription: false
    });
    const [alert, setAlert, onClose] = useAlert();
    const [submitting, setSubmission] = useState(false);
    const [ddls, setDdls] = useState({
        subscriptionTypes: [],
        subscriptionPeriods: [],
    });

    const toggleMute = async () => {

        if(!user.user_muted)
        {
            setModal(state => ({
                ...state,
                mute: true
            }));
        } else
        {
            if(!window.confirm('Confirmer ?')) return;

            const { data: response } = await httpService.put(`/users/${user.user_username}/toggleMute`);

            setuser(state => ({
                ...state,
                user_muted: !user.user_muted
            }));
        }
    }

    const fetchUser = async (id, cb = () => null) => {
        const { data: response} = await httpService.get(`users/${match.params.id}`);
        const user = {
            ...response.data,
            soutiens_count: response.soutiens_count
        };
        setuser(user);
        cb();
        return user;
    };

    const fetchSubscriptionPeriods = async (cb = () => null) => {
        const {data: response} = await httpService.get(`/subscription_periods`);
        cb(response);
        return response;
    }

    const fetchSubscriptionTypes = async (cb = () => null) => {
        const {data: response} = await httpService.get(`/subscription_types`);
        cb(response);
        return response;
    }

    const onSubmit = async (values) => {

        setSubmission(true);
        setAlert(null);

        try {
            const { data: response } = await httpService.put(`/users/${user.user_username}/toggleMute`, values);
            setuser(state => ({
                ...state,
                user_muted: !user.user_muted,
                user_unmute_at: response.data.user_unmute_at
            }));
            onCancel();
        } catch ({response}) {

            const {data, status} = response;

            if(response && status == 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status == 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }

        }
        finally {
            setSubmission(false);
        }

    }

    const onSubmitSubscriptionForm = async (values) => {
        setSubmission(true);
        setAlert(null);

        try {
            const { data: response } = await httpService.post(`/subscriptions/offer_to_user`, {
                ...values,
                subscr_user_id: user?.user_id,
            });
            setuser(state => ({
                ...state,
                user_verified: values.subscr_type === VERIFIED ? true : state?.user_verified,
                user_gold: values.user_gold === GOLD ? true : state?.user_gold,
                subscriptions_count: Number.parseInt(state?.subscriptions_count) + 1
            }));
            onCancel();
        } catch ({response}) {

            const {data, status} = response;

            if(response && status == 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status == 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }

        }
        finally {
            setSubmission(false);
        }
    }

    const onCancel = () => {
        formik.resetForm(initialValues);
        subscriptionFormik.resetForm(subscriptionInitialValues);
        setAlert(null);
        setModal(state => ({
            ...state,
            mute: false,
            subscription: false
        }));
    }

    useEffect(() => {

        try {
            fetchUser(match.params.id, async () => {

                const subscriptionTypes = await fetchSubscriptionTypes()
                const subscriptionPeriods = await fetchSubscriptionPeriods()

                setDdls(state => ({
                    ...state,
                    subscriptionTypes,
                    subscriptionPeriods
                }))

                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            user_unmute_at: Yup.string().ensure().required('Champ obligatoire')
        }),
        onSubmit
    });

    const subscriptionFormik = useFormik({
        initialValues: subscriptionInitialValues,
        validationSchema: Yup.object().shape({
            subscr_type: Yup.string().ensure().required('Champ obligatoire'),
            subscr_period: Yup.string().ensure().required('Champ obligatoire'),
            subscr_user_id: Yup.string().ensure().nullable(),
        }),
        onSubmit: onSubmitSubscriptionForm
    });

    if(fetching) {
        return <FecthingSpinner />
    }

    return (
        <div className="container-fluid">
            <AppBreadcrumb title={`Utilisateur: ${user.user_surname} ${user.user_name} (@${user.user_username})`}>
                <div className="col-md-6 col-sm-12 text-right hidden-xs">
                    <Link to={'/users'}>
                        <button className="btn btn-sm btn-default">
                            <i className="fa fa-arrow-left"></i>{' '}
                            <span>Retour</span>
                        </button>
                    </Link>
                </div>
            </AppBreadcrumb>

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card social">
                        <div className="profile-header d-flex justify-content-between justify-content-center">
                            <div className="d-flex">
                                <div className="mr-3">
                                    <img
                                        className="rounded"
                                        src={user?.profile?.prof_picture || MyOpinionLogo}
                                        height="128px"
                                        width="128px"
                                        alt="Avatar" />
                                </div>
                                <div className="details"><h5 className="mb-0">
                                    {`${user.user_surname} ${user.user_name}`}{' '}
                                    {user.user_verified && (
                                        <i style={{cursor: 'pointer'}} title="Verified" className="fa fa-check-circle-o text-primary mr-1"/>
                                    )}
                                    {user.user_gold && (
                                        <i style={{cursor: 'pointer'}} title="Premium / Gold" className="fa fa-star text-warning mr-1"/>
                                    )}
                                </h5>
                                    <span className="text-light">@{user.user_username}</span>
                                    <p className="mb-0">
                                        <span>Opinions: <strong>{user.opinions_count || 0}</strong></span>{' '}
                                        <br/>
                                        <span>Potes: <strong>{(user.friends_invited_count || 0) + (user.friends_accepted_count || 0)}</strong></span>{' '}
                                        <br/>
                                        <span>Fans: <strong>{user.followers_count || 0}</strong></span>
                                        <br/>
                                        <span>Fan de: <strong>{user.followed_count || 0}</strong></span>
                                        <br/>
                                        <span>Jaimes: <strong>{user.soutiens_count || 0}</strong></span>
                                    </p>
                                </div>
                            </div>
                            <div>
                                {(user?.verification_demand?.verif_demand_status === 'Success') && (
                                    <button className="btn btn-success btn-sm mr-1 font-weight-bold" onClick={() => setModal(state => ({...state, subscription: true}))}>
                                        <i className="fa fa-diamond"></i>{' '}
                                        <span>Offrir un abonnement</span>
                                    </button>
                                )}
                                {user.user_muted ? (
                                    <>
                                        <span className="mr-1 font-weight-bold text-warning">Mute jusqu'au {user.user_unmute_at}</span>
                                        <button className="btn btn-success btn-sm mr-1 font-weight-bold" onClick={toggleMute}>
                                            <i className="fa fa-volume-up"></i>{' '}
                                            <span>Unmute</span>
                                        </button>
                                    </>
                                ) : (
                                    <button className="btn btn-danger btn-sm mr-1 font-weight-bold" onClick={toggleMute}>
                                        <i className="fa fa-volume-off"></i>{' '}
                                        <span>Mute</span>
                                    </button>
                                )}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <div className="list-group">
                        <Link to={`/users/${match.params.id}`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.user_username}`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-user"></i>{' '}
                            <span>Profil</span>
                        </Link>
                        <Link to={`/users/${user.user_username}/opinions`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.user_username}/opinions`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-pencil"></i>{' '}
                            <span>Opinions ({user.opinions_count || 0})</span>
                        </Link>
                        <Link to={`/users/${match.params.id}/agoras`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.user_username}/agoras`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-globe"></i>{' '}
                            <span>Agoras ({user.agoras_count || 0})</span>
                        </Link>
                        <Link to={`/users/${match.params.id}/comments`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.user_username}/comments`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-comments"></i>{' '}
                            <span>Commentaires ({user.comments_count || 0})</span>
                        </Link>
                        <Link to={`/users/${match.params.id}/friends`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.user_username}/friends`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-group"></i>{' '}
                            <span>Potes ({(user.friends_invited_count || 0) + (user.friends_accepted_count || 0)})</span>
                        </Link>
                        <Link to={`/users/${match.params.id}/followers`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.user_username}/followers`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-star"></i>{' '}
                            <span>Fans ({user.followers_count || 0})</span>
                        </Link>
                        <Link to={`/users/${match.params.id}/subscriptions`} className={classnames({
                            'list-group-item list-group-item-action': true,
                            'active':  window.location.pathname === `/users/${user.user_username}/subscriptions`
                                ? "active"
                                : ""
                        })}>
                            <i className="fa fa-diamond"></i>{' '}
                            <span>Subscriptions ({user.subscriptions_count || 0})</span>
                        </Link>
                    </div>
                </div>
                <div className="col-md-9">
                    <Switch>
                        <Route
                            path={`/users/:id`}
                            exact
                            render={(props) => <UserProfile user={user} {...props} />}
                        />
                        <Route
                            path={`/users/:id/opinions`}
                            render={(props) => <UserOpinions user={user} {...props} />}
                        />
                        <Route
                            path={`/users/:id/comments`}
                            render={(props) => <UserComments user={user} {...props} />}
                        />
                        <Route
                            path={`/users/:id/agoras`}
                            render={(props) => <UserAgoras user={user} {...props} />}
                        />
                        <Route
                            path={`/users/:id/friends`}
                            render={(props) => <UserFriends user={user} {...props} />}
                        />
                        <Route
                            path={`/users/:id/followers`}
                            render={(props) => <UserFollowers user={user} {...props} />}
                        />
                        <Route
                            path={`/users/:id/subscriptions`}
                            render={(props) => <UserSubscriptions user={user} {...props} />}
                        />
                    </Switch>
                </div>
            </div>

            <div className={`modal fade ${modal.mute ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Mute utilisateur</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <AppDatePicker
                                    label="Invisible jusqu'au ?"
                                    className="form-control"
                                    error={formik.errors.user_unmute_at}
                                    touched={formik.touched.user_unmute_at}
                                    value={formik.values.user_unmute_at}
                                    onChange={(date) => formik.setFieldValue('user_unmute_at', date)}
                                    required
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancel}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Encour...' : 'Enregistrer'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <div className={`modal fade ${modal.subscription ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={subscriptionFormik.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Abonnement gratuit</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <AppSelect
                                    name="subscr_type"
                                    label="Type d'abonnement"
                                    placeholder="Selectionnez le type d'abonnement"
                                    value={subscriptionFormik.values.subscr_type}
                                    onChange={subscriptionFormik.handleChange}
                                    onBlur={subscriptionFormik.handleBlur}
                                    error={subscriptionFormik.errors.subscr_type}
                                    touched={subscriptionFormik.touched.subscr_type}
                                    required
                                >
                                    {ddls.subscriptionTypes.map(obj => (
                                        <option key={obj?.value} value={obj?.value}>{obj?.label}</option>
                                    ))}
                                </AppSelect>
                                <AppSelect
                                    name="subscr_period"
                                    label="Duree de l'abonnement"
                                    placeholder="Selectionnez la duree de l'abonnement"
                                    value={subscriptionFormik.values.subscr_period}
                                    onChange={subscriptionFormik.handleChange}
                                    onBlur={subscriptionFormik.handleBlur}
                                    error={subscriptionFormik.errors.subscr_period}
                                    touched={subscriptionFormik.touched.subscr_period}
                                    required
                                >
                                    {ddls.subscriptionPeriods.map(obj => (
                                        <option key={obj?.value} value={obj?.value}>{obj?.label}</option>
                                    ))}
                                </AppSelect>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancel}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Encour...' : 'Enregistrer'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default UserDetails;