import React, {useEffect, useState} from 'react';
import queryString from "query-string";
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {Link} from "react-router-dom";

const VerificationDemandList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const initialValues = {}

    const [fetching, setFetching] = useState(true);
    const [verifications, setVerifications] = useState([]);

    const fetchVerifications = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/verifications`, {
            params
        });
        setVerifications(response.data);
        cb();
    };

    useEffect(() => {

        try {

            fetchVerifications(initialValues, () => {
                setFetching(false);
            })

        } catch (e) {
            throw e;
        }

    }, []);

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Verifications des profils utilisateurs" />

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-custom spacing8">
                                <thead>
                                <tr>
                                    <th>Photo</th>
                                    <th>Utilisateur</th>
                                    <th>Pays</th>
                                    <th>Date de soumission</th>
                                    <th>Statut</th>
                                    <th>-</th>
                                </tr>
                                </thead>
                                <tbody>
                                {verifications.map(verification => (
                                    <tr key={verification?.verif_demand_id}>
                                        <td className="w60">
                                            <img src={verification?.user?.profile?.prof_picture || MyOpinionLogo} data-toggle="tooltip"
                                                 data-placement="top" title="Avatar Name" alt="Avatar"
                                                 className="w35 rounded"/>
                                        </td>
                                        <td>
                                            {`${verification?.user?.user_surname} ${verification?.user?.user_name}`} (@{verification?.user?.user_username}){' '}
                                        </td>
                                        <td>
                                            <span>{verification?.user?.user_ip_pays || '-'}</span>
                                        </td>
                                        <td>{verification?.verif_demand_submitted_at}</td>
                                        <td>
                                            {verification?.verif_demand_status === 'Pending' && (
                                                <span className="badge badge-warning  ml-0 mr-0">En attente</span>
                                            )}
                                            {verification?.verif_demand_status === 'Success' && (
                                                <span className="badge badge-success  ml-0 mr-0">Valide</span>
                                            )}
                                            {verification?.verif_demand_status === 'Failed' && (
                                                <span className="badge badge-danger  ml-0 mr-0">Refuse</span>
                                            )}
                                        </td>
                                        <td>
                                            <Link to={`/verifications/${verification?.verif_demand_id}/details`}>
                                                <button className="btn btn-outline-info btn-sm font-weight-bold">
                                                    <i className="fa fa-eye"></i>{' '}
                                                    Consulter
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VerificationDemandList;